<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.2247 0H7.97326C7.3166 0 7.3166 1.05339 7.97326 1.05339H34.6993V22.5162H24.9808L23.9301 21.4628H33.1234C33.386 21.4628 33.6487 21.2653 33.6487 20.9361V2.56764C33.6487 2.30429 33.4517 2.04094 33.1234 2.04094H3.31098C3.04831 2.04094 2.78565 2.23845 2.78565 2.56764V20.9361C2.78565 21.1995 2.98265 21.4628 3.31098 21.4628H12.5042L11.4536 22.5162H1.80066V1.05339H5.28096C5.93762 1.05339 5.93762 0 5.28096 0H1.34099C0.946998 0 0.75 0.197511 0.75 0.526695V23.0429C0.75 23.3062 0.946998 23.5696 1.27533 23.5696H10.4686L8.76126 25.2813C8.43293 25.6105 8.62993 26.1372 9.08959 26.1372L11.2566 26.3347L11.4536 28.5074C11.5192 28.9024 12.0446 29.0999 12.3072 28.8365L16.5755 24.5571C17.5605 24.8863 18.8082 24.8863 19.8588 24.5571L20.6468 25.3472C21.1065 25.808 21.8288 25.0838 21.3691 24.623L20.8438 24.0963C21.6975 23.5696 22.4198 22.8454 22.8795 21.9237L26.1628 25.2155L24.6524 25.3472C24.3898 25.3472 24.1928 25.5447 24.1928 25.808L24.0614 27.3223L23.0765 26.2689C22.6168 25.808 21.8945 26.5322 22.3541 26.9931L24.1928 28.8365C24.5211 29.1657 25.0464 28.9682 25.0464 28.5074L25.2434 26.3347L27.4104 26.1372C27.8044 26.0714 28.0014 25.5447 27.7387 25.2813L26.0314 23.5696H35.2247C35.4873 23.5696 35.75 23.3721 35.75 23.0429V0.526695C35.6843 0.197511 35.4873 0 35.2247 0ZM3.8363 3.09433H32.6637V20.4753H23.4048C23.9958 17.3151 21.5005 14.2208 18.2172 14.2866C14.9995 14.2208 12.4386 17.3151 13.0296 20.4753H3.8363V3.09433ZM12.3729 27.3223L12.2416 25.808C12.2416 25.5447 12.0446 25.3472 11.7819 25.3472L10.2716 25.2155L13.5549 21.9237C14.0145 22.8454 14.7369 23.5696 15.5905 24.0963L12.3729 27.3223ZM18.2172 23.7671C15.9189 23.7671 14.0145 21.8578 14.0145 19.5535C14.2772 13.9574 22.2228 13.9574 22.4855 19.5535C22.4855 21.8578 20.5811 23.7671 18.2172 23.7671Z"
      :fill="`${color}`"
    />
    <path
      d="M18.2179 16.4592C16.5763 16.4592 15.1973 17.8418 15.1973 19.4877C15.3943 23.5038 21.0415 23.5038 21.2385 19.4877C21.2385 17.8418 19.9252 16.4592 18.2179 16.4592ZM18.2179 21.5287C17.1016 21.5287 16.2479 20.607 16.2479 19.5536C16.3793 16.9201 20.1222 16.9201 20.2535 19.5536C20.2535 20.607 19.3342 21.5287 18.2179 21.5287Z"
      :fill="`${color}`"
    />
    <path
      d="M10.9936 9.5464H25.4401C25.7028 9.5464 25.9655 9.34889 25.9655 9.01971V5.72787C25.9655 5.46452 25.7685 5.20117 25.4401 5.20117H10.9936C10.7309 5.20117 10.4683 5.39868 10.4683 5.72787V9.08555C10.4683 9.34889 10.7309 9.5464 10.9936 9.5464ZM11.5189 6.25456H24.9805V8.55885H11.5189V6.25456Z"
      :fill="`${color}`"
    />
    <path d="M6.46344 12.3774H29.9719C30.6286 12.3774 30.6286 11.3898 29.9719 11.324H6.46344C5.80678 11.3898 5.80678 12.3774 6.46344 12.3774Z" :fill="`${color}`" />
    <path d="M29.9721 14.2207H24.5218C23.8651 14.2207 23.8651 15.2083 24.5218 15.2741H29.9721C30.6944 15.2083 30.6944 14.2207 29.9721 14.2207Z" :fill="`${color}`" />
    <path d="M29.9708 17.0518H26.0965C25.4398 17.0518 25.4398 18.0393 26.0965 18.1052H29.9708C30.6931 18.0393 30.6931 17.0518 29.9708 17.0518Z" :fill="`${color}`" />
    <path d="M11.9137 14.2207H6.46344C5.80678 14.2207 5.80678 15.2083 6.46344 15.2741H11.9137C12.636 15.2083 12.636 14.2207 11.9137 14.2207Z" :fill="`${color}`" />
    <path d="M10.3377 17.0518H6.46344C5.80678 17.0518 5.80678 18.0393 6.46344 18.1052H10.3377C11.0601 18.0393 11.0601 17.0518 10.3377 17.0518Z" :fill="`${color}`" />
    <path d="M8.30158 7.90031C8.95824 7.90031 8.95824 6.84692 8.30158 6.84692C7.64492 6.84692 7.64492 7.90031 8.30158 7.90031Z" :fill="`${color}`" />
    <path d="M28.1329 7.90031C28.7895 7.90031 28.7895 6.84692 28.1329 6.84692C27.4762 6.84692 27.4762 7.90031 28.1329 7.90031Z" :fill="`${color}`" />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '36'
    },
    height: {
      type: String,
      default: '29'
    }
  }
}
</script>
